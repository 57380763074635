export const customProductPageScripts = () => {
    const product = document.querySelector('.customProductPage');
    const relateLinks = document.querySelectorAll('a.product-relate-to-section');
    if(product && relateLinks){
        relateLinks.forEach(item => {
            let href = item.getAttribute('href');
            let section = product.querySelector(href);
            if(section){
                item.addEventListener('click', e => {
                    e.preventDefault();
                    window.scrollTo({
                        top: section.offsetTop,
                        left: 0,
                        behavior: 'smooth'
                    })
                })
            }
        })
    }

    let productVariation = jQuery('form.variations_form');
    if(productVariation){
        productVariation.on('show_variation', function(event, data){
            let single_variation = document.querySelector('.single_variation_wrap');
            if(!data.is_in_stock){
                single_variation.classList.add('product-out-of-stock');
            } else{
                single_variation.classList.remove('product-out-of-stock');
            }
        });
    }
}
