const axios = require("axios");
export function navInit() {
  const burgerMobile = document.querySelector('.headerMobile .nav_burger');
  if(burgerMobile){
    burgerMobile.addEventListener('click', () => {
      let headerMobileMenus = document.querySelector('.headerMobile .headerMobile__wrapper--menus');
      if(headerMobileMenus.classList.contains('opened')){
        headerMobileMenus.classList.remove('opened');
        burgerMobile.classList.remove('opened');
      } else{
        headerMobileMenus.classList.add('opened')
        burgerMobile.classList.add('opened')
      }
    })
  }
  const menuItems = document.querySelectorAll('.headerMobile .menu-item');
  if(menuItems){
    menuItems.forEach(item => {
      let menuHasSubmenu = item.querySelector('.menu-item--opener');
      if(menuHasSubmenu){
        menuHasSubmenu.addEventListener('click', e => {
          e.preventDefault();
          let submenu = item.querySelector('.menu__submenu');
          if(submenu.classList.contains('opened')){
            submenu.classList.remove('opened');
            menuHasSubmenu.classList.remove('opened');
          } else{
            submenu.classList.add('opened');
            menuHasSubmenu.classList.add('opened');
          }
        })
      }
    })
  }
  const miniCart = document.querySelector('#mini-cart');
  if(miniCart){
    miniCart.querySelector('span.close').addEventListener('click', () => miniCart.classList.remove('opened'))
    const miniCartButton = document.querySelector('.headerDesktop .shopButton--cart');
    if(miniCartButton){
      miniCartButton.addEventListener('click', e => {
        e.preventDefault();
        if(miniCart.classList.contains('opened')){
          miniCart.classList.remove('opened')
        } else {
          miniCart.classList.add('opened')
        }
      })
    }

    let miniCartQty = document.querySelectorAll('.mini-cart-qty');

    if(miniCartQty){
      miniCartQty.forEach(item => {
        if(parseInt(item.innerHTML) > 0){
          item.style.display = "flex";
        }
      })
      jQuery(document.body).on( 'added_to_cart removed_from_cart', function(){
        axios.get(`${ajax.url}?action=get_mini_cart_qty`).then(res => {
          miniCartQty.forEach(item => {
            if(res.data){
              item.innerHTML = res.data;
            }
            if(parseInt(item.innerHTML) > 0){
              item.style.display = "flex";
            }
          })
        })
      });
    }
  }

   //ajax add to cart
   window.addEventListener("DOMContentLoaded", () => {
    const addToCartEvent = () => {
      const addToCartButtons = document.querySelectorAll(".add-to-cart");
      for (const addToCart of addToCartButtons) {
        addToCart.onclick = () => {
          addToCart.classList.add("loading");
          addToCart.textContent = "";
          axios
            .get(addToCart.dataset.url)
            .then(() => {
              addToCart.classList.remove("loading");
              addToCart.classList.add("loaded");
              location.reload();
            })
            .catch(e => {
              if (e.response.status !== 400) {
                addToCart.classList.remove("loading");
                addToCart.classList.add("failed");
                location.reload();
              }
            })
            .finally(() => {
              location.reload();
            });
        };
      }
    };
    window.addEventListener(
      "load",
      function() {
        addToCartEvent();
        document.addEventListener("DOMNodeInserted", addToCartEvent, false);
      },
      false
    );
    document.onpropertychange = addToCartEvent;
  });

  const elements = document.querySelectorAll('.menu_mobile li.menu-item-has-children');

  elements.forEach(element => {
    const item = element.querySelector('a');
    item.appendChild(document.createElement("span"));

    item.querySelector('span').addEventListener("click", (e)=>{
      e.preventDefault();
      if(item.classList.contains('open')) {
        item.classList.remove('open');
        element.querySelector('.sub-menu').classList.remove('open');
      } else {
        item.classList.add('open');
        element.querySelector('.sub-menu').classList.add('open');
      }
    })
  })
}
