import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const customArchiveProductScripts = () => {
    const shopElements = document.querySelector('.customArchiveProduct__shopElements');
    if(shopElements && window.innerWidth > 992){
        const sideBar = shopElements.querySelector('.customArchiveProduct__sidebar');
        const mainWrapper = shopElements.querySelector('.customArchiveProduct__loop');
        if(mainWrapper){
            gsap.to(sideBar, {scrollTrigger: {
                    trigger: mainWrapper,
                    pin: sideBar,
                    start: 'top 150px',
                    end: () => `+=${mainWrapper.clientHeight}`,
                }});
        }
    }
}