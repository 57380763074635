import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function mediaCarousel() {

    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.mediaCarousel__sliders');
        if (blocks){
        blocks.forEach(element=> {
        var mediaCarousel = new Splide( element, {
          perPage    : 3,
          perMove    : 1,
          type       : 'loop',
          gap        : 8,
          pagination : false,
          arrows     : false,

          breakpoints: {
            990: {
              perPage : 1,
              perMove : 1,
              pagination : true,
              
            },
            525: {
              perPage : 1,
              perMove : 1,
              gap     : 8,
              pagination : true,
           
            }
          }
        } );

        mediaCarousel.on('mounted', ()=>{
          if(mediaCarousel.length <= mediaCarousel.options.perPage ) {
            mediaCarousel.options = { arrows: false, pagination: false, drag: false };
            mediaCarousel.destroy();
            mediaCarousel.mount();
          }
        })

        mediaCarousel.mount();
      })
      
    }})};