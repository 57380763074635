export const initWidgetShopFiltersModal = () => {
    const opener = document.querySelector('.shopFiltersModal .shopFiltersModal--opener');
    const filtersModal = document.querySelector('.shopFiltersModal .shopFiltersModal__modal');
    if(opener && filtersModal){
        opener.addEventListener('click', () => {
            filtersModal.classList.contains('opened') ? filtersModal.classList.remove('opened') : filtersModal.classList.add('opened');
        })
        const closeBtn = filtersModal.querySelector('span.close');
        closeBtn.addEventListener('click', () => {
            filtersModal.classList.remove('opened');
        })
        let applyBtn = filtersModal.querySelector('.apply-filters');
        applyBtn.addEventListener('click', () => {
            filtersModal.classList.remove('opened');
        })
    }
}