import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const blockProductsScripts = () => {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockGirlsOfPinto__sliders');

        blocks.forEach(block => {
            let blockGirlsOfPintoSlide = new Splide(block, {
                gap: "24px",
                cover: true,
                arrows: false,
                type: "loop",
                perPage: 4,
                perMove: 1,
                breakpoints: {
                    1200: {
                        perPage : 3,
                    },
                    768: {
                        perPage : 2,
                    },
                    576: {
                        perPage : 1,
                    }
                }
              });


            blockGirlsOfPintoSlide.on('mounted', ()=>{
                if(blockGirlsOfPintoSlide.length <= blockGirlsOfPintoSlide.options.perPage ) {
                    blockGirlsOfPintoSlide.options = { arrows: false, pagination: false, drag: false };
                    blockGirlsOfPintoSlide.destroy();
                    blockGirlsOfPintoSlide.mount();
                }
            })

            blockGirlsOfPintoSlide.mount();
        })
    })
}