import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { navInit } from './modules/navigations';
import { homesiteSliders } from './modules/homesiteSliders';
import { productCustomGallery } from './modules/productCustomGallery';
import { productCardSlider } from './modules/productCardSlider';
import { productDescriptionScript } from './modules/productDescriptionScript';
import { customArchiveProductScripts } from './modules/customArchiveProductScripts';
import { mediaCarousel } from '../../includes/blocks/block-media-carousel/scripts';
import { customProductPageScripts } from './modules/customProductPageScripts';
import { QualityCarousel } from '../../includes/blocks/block-quality-of-service/scripts';

import { infoCarousel } from '../../includes/blocks/block-info-carousel/scripts';

import { blockProductsScripts } from '../../includes/blocks/block-girls-of-pinto/scripts';
import { initWidgetShopFiltersModal } from '../../includes/widgets/widget-shop-filters-modal/scripts';
import {blockHeroSlider} from "../../includes/blocks/block-hero-slider/scripts";

fontawesome.library.add(far, fab, fas);

if(document.querySelector('#header') && document.querySelector('#header_mobile')){
  navInit();
}
if(document.querySelector('.sectionBannerHomesiteSlider__slider')){
  homesiteSliders();
}
if(document.querySelector('.productCardSlider')){
  productCardSlider();
}

if(document.querySelector('.blockQualityCarouselWithWysiwyg__carousel--sliders')){
  QualityCarousel();
}

if ( document.querySelector('.blockInfoCarousel__sliders') ){
  infoCarousel();
}

if ( document.querySelector('.mediaCarousel__sliders') ){
  mediaCarousel();
}

if(document.querySelector('.productCustomGallery')) {
  productCustomGallery();
}

if(document.querySelector('#wooProductDescription__wrapper')) {
  productDescriptionScript();
}

if(document.querySelector('.customProductPage ')){
  customProductPageScripts();
}

if(document.querySelector('.customArchiveProduct')) {
  customArchiveProductScripts();
}

if(document.querySelector('.shopFiltersModal--opener')){
  initWidgetShopFiltersModal();
}

if(document.querySelector('.heroSlider__slider')){
  blockHeroSlider();
}

//scripts for blocks
if (document.querySelector(".blockGirlsOfPinto__sliders")) { blockProductsScripts(); }


(() => {
  //change cart quantity
  function incrementCart() {
    const updateButton = document.querySelector(".actions>button");
    this.parentNode.querySelector(`input.text`).stepUp();
    updateButton.disabled = false;
    updateButton.click();
  }
  function decrementCart() {
    const updateButton = document.querySelector(".actions>button");
    this.parentNode.querySelector(`input.text`).stepDown();
    updateButton.disabled = false;
    updateButton.click();
  }
  function listenOnCart() {
    const arrowsUp = document.querySelectorAll(".qty_button.plus");
    const arrowsDown = document.querySelectorAll(".qty_button.minus");
    for (const arrow of arrowsUp) {
      arrow.onclick = incrementCart;
    }
    for (const arrow of arrowsDown) {
      arrow.onclick = decrementCart;
    }
  }
  listenOnCart();
  const config = { attributes: true, childList: true, subtree: true };
  const observer = new MutationObserver(listenOnCart);
  observer.observe(document, config);


  /**
   * Disable WPCF7 button while it's submitting
   * Stops duplicate enquiries coming through
   */
  if(document.querySelector('form.wpcf7-form')) {
    const formCF7 = document.querySelector('form.wpcf7-form');
    document.addEventListener( 'wpcf7submit', function( event ) {

      // find only disbaled submit buttons
      const button = formCF7.querySelector('.wpcf7-submit[disabled]');

      // grab the old value
      const old_value = button.getAttribute('data-value');

      // enable the button
      button.removeAttribute('disabled');

      // put the old value back in
      button.value = old_value;

    }, false );

    formCF7.addEventListener('submit',function() {
      if(formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

      const button = formCF7.querySelector('input[type=submit]');
      const current_val = button.value;

      // store the current value so we can reset it later
      button.setAttribute('data-value', current_val);

      // disable the button
      button.setAttribute("disabled", true);

      // tell the user what's happening
      button.value = "Wysyłanie...";

    });
  }
})();
