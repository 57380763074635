import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function QualityCarousel() {

    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockQualityCarouselWithWysiwyg__carousel--sliders');
        if (blocks){
        blocks.forEach(element=> {
        var QualityCarousel = new Splide( element, {
          perPage    : 3,
          perMove    : 1,
          type       : 'loop',
          gap        : 8,
          pagination : false,
          arrows     : false,

          breakpoints: {
            990: {
              perPage : 1,
              perMove : 1,
              pagination : true,

            },
            525: {
              perPage : 1,
              perMove : 1,
              gap     : 8,
              pagination : true,

            }
          }
        } );

        QualityCarousel.on('mounted', ()=>{
          if(QualityCarousel.length <= QualityCarousel.options.perPage ) {
            QualityCarousel.options = { arrows: false, pagination: false, drag: false };
            QualityCarousel.destroy();
            QualityCarousel.mount();
          }
        })

        QualityCarousel.mount();
      })

    }})};
