import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function infoCarousel() {
    document.addEventListener( 'DOMContentLoaded', function () {
        const blocks = document.querySelectorAll('.blockInfoCarousel__sliders');
        if (blocks){
            blocks.forEach(element=> {
                var infoCarousel = new Splide( element, {
                    perPage    : 3,
                    perMove    : 1,
                    type       : 'loop',
                    gap        : 8,
                    pagination : false,
                    arrows     : false,

                    breakpoints: {
                        990: {
                          perPage : 1,
                          perMove : 1,
                          pagination : true,

                        },
                        525: {
                          perPage : 1,
                          perMove : 1,
                          gap     : 8,
                          pagination : true,

                        }
                    }
                });

                infoCarousel.on('mounted', ()=>{
                    if(infoCarousel.length <= infoCarousel.options.perPage ) {
                        infoCarousel.options = { arrows: false, pagination: false, drag: false };
                        infoCarousel.destroy();
                        infoCarousel.mount();
                    }
                })

                infoCarousel.mount();
            })
        }
    });
};
