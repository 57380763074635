import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const blockHeroSlider = () => {
    const blocks = document.querySelectorAll('.heroSlider__slider');

    if(blocks){
        blocks.forEach(block => {
            new Splide(block, {
                arrows: true,
                pagination: false,
                autoplay: true,
                interval: 6000,
                pauseOnHover: false,
                pauseOnFocus: false,
                resetProgress: false,
                type: "loop",
                perPage: 1,
                perMove: 1
            }).mount();
        })
    }
}