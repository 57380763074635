import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function productCustomGallery() {
  document.addEventListener( 'DOMContentLoaded', function () {
    let primarySlider = new Splide( '#primary-slider', {
      type       : 'loop',
      pagination : false,
      arrows     : true,
    } ).mount();
  });
}

